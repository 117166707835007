<template>
  <div class="referral-marketing tw-overflow-x-hidden">
    <referral-marketing-hero :link="referralLink" :is-successful="isSuccessful" :is-sidebar-visible="!isEmployee" @cta-click="handlePaymentSetupClick" />

    <div class="referral-marketing-container 2xl:tw-max-w-screen-xl lg:tw-max-w-screen-app-content-width tw-p-layout-1 sm:tw-p-layout-3 lg:tw-p-layout-4 tw-w-full tw-mx-auto">
      <section id="how-it-works" class="tw-mt-layout-1 lg:tw-mt-layout-4">
        <app-heading tag="h2" size="h3" weight="semi-bold" color="headings" class="md:tw-mb-layout-2 tw-mb-space-4 md:tw-text-h2 lg:tw-text-h1 md:tw-font-medium tw-text-center">
          How It Works
        </app-heading>
        <div class="sm:tw-flex tw-gap-layout-1 2xl:tw-gap-layout-3 tw-block">
          <app-icon-card
            v-for="(step, index) in steps"
            :key="index"
            :icon="index+1"
            class="tw-mb-space-1 last:tw-mb-0 sm:tw-mb-0"
            :title="step.title"
            :desc="step.desc" />
        </div>
      </section>

      <section id="what-youll-earn" class="tw-mt-layout-4">
        <app-heading tag="h2" size="h3" weight="semi-bold" color="headings" class="md:tw-mb-layout-2 tw-mb-space-4 md:tw-text-h2 lg:tw-text-h1 md:tw-font-medium tw-text-center">
          What You'll Earn
        </app-heading>

        <div class="sm:tw-flex sm:tw-items-start tw-gap-layout-1 2xl:tw-gap-layout-3 tw-items-center tw-justify-between tw-block">
          <app-card :hover="false" class="tw-mb-space-6 tw-flex tw-flex-col">
            <template>
              <marketing-coins-illustration class="tw-max-h-52 tw-block tw-w-full tw-mx-auto" />
            </template>
            <template #footer>
              <app-heading level="h3" size="h5" weight="semi-bold" color="headings" class="sm:tw-text-h4 tw-mt-space-4 sm:tw-mt-space-4 tw-mb-space-2">
                Earn 💰 per conversion
              </app-heading>
              <app-text type="p" weight="medium" size="sm">
                As a referral partner, you’re eligible to earn payouts for every new sign up that you drive from a first-time Jumbleberry customer. Learn more about our qualified referral payouts once you apply.
              </app-text>
            </template>
          </app-card>

          <app-card :hover="false" class="tw-flex tw-flex-col">
            <template>
              <marketing-chart-illustration class="tw-max-h-52 tw-block tw-w-full tw-mx-auto" />
            </template>
            <template #footer>
              <app-heading level="h3" size="h5" weight="semi-bold" color="headings" class="sm:tw-text-h4 tw-mt-space-4 sm:tw-mt-space-4 tw-mb-space-2">
                Unlimited referrals
              </app-heading>
              <app-text type="p" weight="medium" size="sm">
                We don’t place caps on the number of referrals our partners can send, so in compliance with our affiliate terms and conditions, you may receive commissions as long as your leads are converting.
              </app-text>
            </template>
          </app-card>
        </div>
      </section>

      <section id="faq" class="tw-mt-layout-4">
        <app-heading tag="h2" size="h3" weight="semi-bold" color="headings" class="md:tw-mb-layout-2 tw-mb-space-4 md:tw-text-h2 lg:tw-text-h1 md:tw-font-medium tw-text-center">
          Frequently Asked Questions
        </app-heading>

        <app-accordion v-model="faqActiveName" :data="faqData">
          <template slot="title" slot-scope="row">
            <div class="tw-flex tw-justify-between tw-w-full">
              {{ row.item.title }}
              <i :class="faqActiveName === row.item.name ? 'el-icon-minus' : 'el-icon-plus'" />
            </div>
          </template>
        </app-accordion>
      </section>

      <section class="tw-mt-layout-3 tw-mb-layout-4">
        <app-divider align="right">
          <app-button size="mini" class="tw-bg-jb-indigo xs:tw-w-48 xs:tw-px-4 tw-w-full" @click="handlePaymentSetupClick">
            {{ isSuccessful === false ? 'Set up Payment Details' : 'Edit Payment Details' }}
          </app-button>
        </app-divider>
      </section>
    </div>

    <el-dialog :visible.sync="isDialogVisible" :show-close="false" class="referral-marketing-dialog">
      <div slot="title">
        <app-heading level="h4" weight="semi-bold" color="headings" class="tw-m-0">
          Confirmation Required
        </app-heading>
      </div>
      <app-text type="p" size="sm" weight="medium">
        By checking the box below, you agree to our
      </app-text>
      <app-link :external="true" :href="referralTermsLink" class="!tw-text-jb-indigo hover:tw-underline tw-font-medium tw-cursor-pointer">
        Terms and Conditions
      </app-link>
      <app-checkbox :checked="referralTermsChecked" class="tw-pt-space-4" @change="toggleReferralTermCheck" />
      <div slot="footer" class="dialog-footer tw-flex tw-justify-end">
        <app-button state="secondary" class="tw-border-jb-indigo tw-text-jb-indigo tw-font-medium tw-border" @click="isDialogVisible = false">
          Cancel
        </app-button>
        <app-button class="tw-ml-space-2 tw-border-jb-indigo tw-bg-jb-indigo disabled:tw-bg-jb-indigo-disabled hover:tw-bg-jb-indigo-dark tw-font-medium tw-text-white" state="primary" :disabled="!referralTermsChecked" @click="$router.push({name: 'referral-setup'})">
          Confirm
        </app-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import AppLink from '@/components/AppLink.vue';
import AppText from '@/components/AppText';
import AppHeading from '@/components/AppHeading';
import AppButton from '@/components/AppButton';
import AppAccordion from '@/components/AppAccordion';
import AppCard from '@/components/AppCard.vue';
import AppDivider from '@/components/AppDivider.vue';
import AppIconCard from '@/components/AppIconCard.vue';
import ReferralMarketingHero from '@/components/referral/ReferralMarketingHero.vue';
import MarketingChartIllustration from '@/assets/svg/marketing-chart-illustration.svg';
import MarketingCoinsIllustration from '@/assets/svg/marketing-coins-illustration.svg';
import Breakpoints from '@/mixins/Breakpoints';
import { EMPLOYEE } from '@/constants/orgTypes';
import AppCheckbox from '@/components/AppCheckbox';
import { Dialog } from 'element-ui';
import { FAQ_DATA } from '@/constants/referral';

export default {
  components: {
    AppLink,
    AppText,
    AppHeading,
    AppAccordion,
    AppButton,
    AppDivider,
    AppIconCard,
    AppCard,
    ReferralMarketingHero,
    MarketingChartIllustration,
    MarketingCoinsIllustration,
    'el-dialog': Dialog,
    AppCheckbox
  },
  mixins: [Breakpoints([768, 1200])],
  data() {
    return {
      steps: [
        { title: 'Invite your friends', desc: 'Send the referral link to your friends to sign up on Jumbleberry.' },
        { title: 'Follow their progress', desc: 'Track sign ups and see how much you earn for each referral.' },
        { title: 'Get paid', desc: 'Earn 1% commission from every sale they make - up to $10,000 USD per referral!' }
      ],
      referralTermsLink: 'https://jumbleberry.com/referral-program.html',
      isDialogVisible: false,
      faqActiveName: ''
    };
  },
  computed: {
    smUp() {
      return this.windowBreakpoint >= 768;
    },
    lg() {
      return this.windowBreakpoint >= 1200;
    },
    isEmployee() {
      return this.$store.getters.organization.type === EMPLOYEE;
    },
    referralLink() {
      return this.$store.getters.referralLink;
    },
    isSuccessful() {
      return this.$store.getters.isSetupComplete;
    },
    referralTermsChecked() {
      return this.$store.getters.referralTermsIsChecked;
    },
    faqData() {
      return FAQ_DATA;
    }
  },
  methods: {
    toggleReferralTermCheck() {
      this.$store.dispatch('setReferralCheck', !this.referralTermsChecked);
    },
    handlePaymentSetupClick() {
      if (!this.isSuccessful) {
        this.isDialogVisible = !this.isDialogVisible;
      } else {
        this.$router.push({ name: 'referral-setup' });
      }
    }
  }
};
</script>

<style lang="scss">
.referral-marketing {
  #what-youll-earn .app-card__body {
    @apply tw-bg-white tw-rounded;
  }

  #faq .el-collapse-item {
    margin-bottom: theme('spacing.space-2');
  }

  .app-checkbox {
    .el-checkbox__inner {
      @apply tw-border-0.5 tw-border-jb-grey-400 tw-w-4 tw-h-4;
    }

    .el-checkbox__input.is-checked > span {
      @apply tw-bg-jb-indigo;
    }

    .el-checkbox__input {
      @apply tw-mt-0.5;
    }

    .el-checkbox__label {
      @apply tw-text-jb-indigo tw--ml-0.5;
    }

    .el-checkbox__input.is-checked + .el-checkbox__label {
      @apply tw-text-jb-indigo;
    }
  }

  .referral-marketing-dialog {
    .el-dialog {
      @apply tw-rounded;

      width: calc(100% - 16px);
      max-width: 400px;
    }

    .el-dialog__header {
      @apply tw-pb-0 tw-pt-space-4 tw-px-space-4;
    }

    .el-dialog__body {
      @apply tw-p-space-4;
    }

    .el-dialog__footer {
      @apply tw-p-space-4;
    }
  }
}
</style>
