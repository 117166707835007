<template>
  <section class="tw-bg-primary-lighter tw-relative">
    <div class="2xl:tw-max-w-screen-2xl tw-relative tw-mx-auto">
      <div
        class="sm:tw-w-1/2 tw-p-layout-1 xs:tw-py-layout-2 sm:tw-px-layout-2 sm:tw-py-layout-3 md:tw-py-layout-4 xs:tw-w-2/3 tw-relative tw-w-full"
        :class="{['sm:!tw-p-layout-2 md:!tw-p-layout-3 lg:!tw-p-layout-4']: isSidebarVisible}">
        <app-heading
          level="h1"
          size="h4"
          weight="semi-bold"
          color="headings"
          class="tw-mb-space-3 sm:tw-text-h3 sm:tw-mb-layout-1 md:tw-text-h1 md:tw-font-medium lg:tw-text-5xl lg:tw-mb-layout-2"
          :class="isSidebarVisible ? ' lg:!tw-text-h1 lg:!tw-mb-layout-1 xl:!tw-text-5xl xl:!tw-mb-layout-2' : ''">
          <!-- TODO: huss fix appheading to accept dynamic class properly!! -->
          {{ headerText }}
        </app-heading>
        <app-text
          type="p"
          size="sm"
          weight="medium"
          class="xs:tw-block tw-mb-space-3 tw-text-jb-grey-700 sm:tw-mb-layout-1 md:tw-text-base lg:tw-text-xl lg:tw-max-w-lg lg:tw-mb-layout-2 tw-hidden"
          :class="isSidebarVisible ? 'md:!tw-max-w-md lg:!tw-text-base lg:!tw-mb-layout-1 lg:!tw-max-w-md xl:!tw-text-xl xl:!tw-max-w-lg xl:!tw-mb-layout-2' : ''">
          {{ headerBody }}
        </app-text>
        <app-copy-text v-if="isSuccessful" :link="link" action-text="Copy" class="tw-w-72 tw-max-w-full !tw-bg-white" />
        <app-button v-else size="mini" class="tw-bg-jb-indigo lg:tw-text-base" @click="$emit('cta-click')">
          Set up Payment Details
        </app-button>
      </div>

      <div
        class="xl:tw-w-4/5 sm:tw-w-1/2 sm:tw--bottom-layout-5 lg:tw--bottom-layout-6 tw-absolute tw-top-0 tw-bottom-0 tw-right-0 tw-z-0 tw-w-1/3 tw-pointer-events-none"
        :class="{['md:!tw-w-3/5']: isSidebarVisible}">
        <referral-marketing-header-xxl v-if="xxl" class="tw-block tw-h-full tw-ml-auto" />
        <referral-marketing-header-lg v-else-if="xl" class="tw-block tw-h-full tw-ml-auto" />
        <referral-marketing-header v-else-if="sm" class="tw-block tw-h-full tw-ml-auto" />
        <gift-box-illustration v-else-if="xs" class="tw-mr-space-2 tw-block tw-h-full tw-ml-auto" />
      </div>
    </div>
  </section>
</template>

<script>
import AppText from '@/components/AppText.vue';
import AppHeading from '@/components/AppHeading.vue';
import AppButton from '@/components/AppButton.vue';
import AppCopyText from '@/components/AppCopyText.vue';
import ReferralMarketingHeader from '@/assets/svg/referral-marketing-header-illustration.svg';
import ReferralMarketingHeaderLg from '@/assets/svg/referral-marketing-header-lg-illustration.svg';
import ReferralMarketingHeaderXxl from '@/assets/svg/referral-marketing-header-xxl-illustration.svg';
import GiftBoxIllustration from '@/assets/svg/gift-box-illustration.svg';
import Breakpoints from '@/mixins/Breakpoints';

export default {
  components: {
    AppText,
    AppHeading,
    AppButton,
    AppCopyText,
    ReferralMarketingHeader,
    ReferralMarketingHeaderLg,
    ReferralMarketingHeaderXxl,
    GiftBoxIllustration
  },
  mixins: [Breakpoints([450, 768, 992, 1200, 1400, 1920])],
  props: {
    link: {
      type: String,
      default: 'www.google.com'
    },
    isSidebarVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    headerText() {
      return this.isSuccessful ? 'Start Referring Today!' : 'Earn 1% on every new referral';
    },
    headerBody() {
      return this.isSuccessful ? 'Use your link below to start referring friends and earning commission.' : 'Earn even more commission when you refer brand-new partners to the Jumbleberry platform.';
    },
    isSuccessful() {
      return this.$store.getters.isSetupComplete;
    },
    xs() { return this.windowBreakpoint >= 450; },
    sm() { return this.windowBreakpoint >= 768; },
    md() { return this.windowBreakpoint >= 992; },
    lg() { return this.windowBreakpoint >= 1200; },
    xl() { return this.windowBreakpoint >= 1400; },
    xxl() { return this.windowBreakpoint >= 1920; }
  }
};
</script>
