<template>
  <div class="app-checkbox">
    <el-checkbox :checked="checked" @change="$emit('change')">
      <span class="!tw-text-jb-indigo hover:tw-underline tw-font-medium tw-cursor-pointer">
        {{ checkboxCopy }}
      </span>
    </el-checkbox>
  </div>
</template>

<script>
export default {
  name: 'AppCheckbox',
  props: {
    checkboxCopy: {
      type: String,
      default: 'I Accept the Terms and Conditions'
    },
    checked: {
      type: Boolean,
      default: false
    }
  }
};
</script>
